body {
    font-family: 'Lucida Console', monospace;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

    /* some more cool stuff for code */

}
