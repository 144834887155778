.full-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  /* background-color: #333; */
}

.text {
  text-align: center;
  font-weight: bold;
  /* charcoal */
  color: #ff4081;
  font-size: 33vw; /* Adjust the value as needed */
  /* character spacing */
  letter-spacing: 0.09em;
  line-height: 0.66;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 99%;
  height: auto;
  /* cant be hihglighted */
  user-select: none;
  
  
}

/* no scroll */
body {
  overflow: hidden;
}

/* special for the letter A and I  */

.text span {

}

.absolute-position {
  position: absolute;
  left: 0;
  top: 0;

  /* transform: translate(-50%, -50%); */
}